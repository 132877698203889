<template>
<div >
    <div id="hd"><div class="wrp">
	<h1><a href="javascript:void(0);" title="店铺总管" style="font-size:18px;">店铺总管授权登录页面</a></h1>
    </div></div>
    <div id="bd" class="wrp">
	<div id="info">
		<i class="ico" :class="status != 1?'ico-fail':''"></i>
		<h2 v-if="status == 1">成功登录店铺总管。</h2>
		<h2 v-if="status == 2">请重新登录电商平台</h2>
		<h2 v-if="status == 3">当前平台已在系统注册过</h2>
		<b id="close" class="btn" href="#" @click="jumpHistory" v-if="status==1">返回店铺总管</b>
		<b id="close" class="btn" href="#" v-if="status != 1 " @click="jumpHistory">关闭本页面</b>
	</div>
    </div>
    <div id="ft"><div class="wrp">
        © 广东东坡纸业有限公司 版权所有
    </div></div>
</div>
</template>

<script>
export default {
    data(){
        return {
            status:1
        }
    },
    methods:{
        jumpHistory(){
            this.$router.push({
                path:'/data_analysis/all'
            })
        }
    },
    mounted(){
        this.status = this.$route.query.status;
        console.log(this.status);
    }
}
</script>

<style>
html,body{padding:0;margin:0;font:12px/1.6 arial,sans-serif}
html{background:#fafafa}
body{background:#efefef;
	background:-webkit-radial-gradient(50% 50%, #fff, #eaeaea 50%);
	background:-moz-radial-gradient(50% 50%, #fff, #eaeaea 50%);
}
img{display:block;border:none}
a{color:#148fd4;text-decoration:none}
.wrp{width:460px;margin:0 auto}
/* button style */
.btn{display:inline-block;padding:5px 15px;font-weight:bold;text-align:center;text-decoration:none;font-size:14px;font-family:"Microsoft YaHei","微软雅黑","Microsoft JhengHei","华文黑体",STHeiti,sans-serif;cursor:pointer;color:#3a3a3a;background:#fff;border:1px solid #d2d2d2;text-shadow:1px 1px 0 #fff;
    transition:all .3s ease-in-out;border-radius:2px;box-shadow:1px 1px 0 #f5f5f5;
    -webkit-transition:all .3s ease-in-out;background:-webkit-linear-gradient(top, #fff, #f5f5f5);
    -moz-transition:all .3s ease-in-out;background:-moz-linear-gradient(top, #fff, #f5f5f5 100%);
    -o-transition:all .3s ease-in-out;
}
.btn:hover{color:#2a2a2a;border-color:#999;background:#f2f2f2;
    box-shadow:1px 1px 2px #d5d5d5;
    background:-webkit-linear-gradient(top, #fff, #f2f2f2);
    background:-moz-linear-gradient(top, #fff, #f2f2f2 100%);
}
.btn:active,.btn[active]{
    background:-webkit-linear-gradient(top, #f2f2f2, #fff);
    background:-moz-linear-gradient(top, #f2f2f2, #fff 100%);
}
.btn:active{
	box-shadow:1px 1px 2px #d5d5d5 inset;
}
.hidden {
    display: none;
}

#hd{padding:8px 0;background:#fff;border-bottom:1px solid #dedede}
#hd h1{margin:0}
#bd{position:relative;height:500px;min-height:500px}
#info{position:absolute;top:50%;width:400px;height:130px;padding-left:110px;margin-top:-65px}
#info h2{margin:10px 0;font-size:16px;font-weight:normal}
#info p{color:#666}
#info .ico{position:absolute;left:0;top:15px;width:81px;height:55px;background:url(../../assets/img/home/auth-ico.png) no-repeat left top}
#info .ico-fail{left:10px;background-position:left -55px}
#ft{padding:10px 0;color:#999;text-align:center;border-top:1px solid #dedede;background:#fafafa}
</style>